<template>
  <el-row id="row1">
    <el-col :span="16" :offset="4">请填写您的手机号和邀请码</el-col>
  </el-row>
  <el-row id="row2">
    <el-col
      :xs="{ span: 16, offset: 4 }"
      :sm="{ span: 6, offset: 9 }"
      :md="{ span: 6, offset: 9 }"
      :lg="{ span: 4, offset: 10 }"
      :xl="{ span: 2, offset: 11 }"
    >
      <el-form ref="form" :model="form" label-width="60px" v-loading="loading">
        <el-form-item label="手机号">
          <el-input
            v-model="form.mobile"
            placeholder="请输入你的手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="邀请码">
          <el-input
            v-model="form.code"
            placeholder="请从医生处获取邀请码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import { ref } from "vue";
import axios from 'axios'

export default {
  name: "AndroidForm",
  props: {
    msg: String,
  },
  setup() {
    const loading = ref(false);

    return {
      loading,
    };
  },
  data() {
    return {
      form: {
        mobile: "",
        code: "",
      },
    };
  },
  methods: {
    onSubmit() {
          if(this.form.mobile == "" || this.form.mobile == null || this.form.mobile == undefined){
              alert('手机号不能为空')
              return false
          }
          if(this.form.code == "" || this.form.code == null || this.form.code == undefined){
              alert('邀请码不能为空')
              return false
          }
          this.loading = true;
          let params = {
              code: this.form.code,
              phone: this.form.mobile,
              type: 0
          };
          let api = "https://api.medmotion.cn/appserver/invite/codeSubmit";
          axios.post(api, params).then((response) => {
          let obj = response.data.code
          if (obj == 2000) {
              this.$router.push("anddownload");
          } else {
              alert(response.data.msg)
              this.loading = false;
          }
          }).catch(function (error) {
              console.log(error);
              alert('系统错误')
              this.loading = false;
          });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#row1 {
  margin-top: 10vh;
}

#row2 {
  margin-top: 20vh;
}
</style>
