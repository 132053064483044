import { createRouter, createWebHashHistory } from 'vue-router'
import OS from '../components/os.vue'
import AndroidForm from '../components/android/form.vue'
import AndroidDownload from '../components/android/download.vue'
import IOSForm from '../components/ios/form.vue'
import IOSDownload from '../components/ios/download.vue'
import AppDownload from '../components/appdownload.vue'

const router = new createRouter({

    routes :[
        {
            path: '/',
            component: OS
        },
        {
            path: '/os',
            component: OS
        },
        {
            path: '/andform',
            component: AndroidForm
        },
        {
            path: '/anddownload',
            component: AndroidDownload
        },
        {
            path: '/iosform',
            component: IOSForm
        },
        {
            path: '/iosdownload',
            component: IOSDownload
        },
        {
            path: '/appdownload',
            component: AppDownload
        },
    ],
    history: createWebHashHistory(),
})

export default router
