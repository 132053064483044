import { createApp } from 'vue'
import App from './App.vue'
import Router from './router/index.js'
import ElementUI from 'element-plus';
import 'element-plus/dist/index.css';

createApp(App)
    .use(ElementUI)
    .use(Router)
    .mount('#app')
