<template>
  <el-row id="row1">
    <el-col :span="16" :offset="4"
      ><div>请扫码并按提示流程下载App</div>
      <div>完成注册后即可开启您的专属康复方案！</div></el-col
    >
  </el-row>
  <el-row id="row2">
    <el-col :span="12" :offset="6">
      <el-image :src="medmotion_ios_qrcode"></el-image>
      <div>悦行动</div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "IOSDownload",
  props: {
    msg: String,
  },
  setup() {
    const medmotion_ios_qrcode = require("@/assets/medmotion_ios_qrcode_v3_appstore.png");

    return {
      medmotion_ios_qrcode,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#row1 {
  margin-top: 10vh;
}

#row2 {
  margin-top: 20vh;
}
</style>
