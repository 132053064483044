<template>
  <el-row id="row1">
    <el-col :span="16" :offset="4">请选择您要安装的悦行动App版本</el-col>
  </el-row>
  <el-row id="row2">
    <el-col :span="3" :offset="8">
      <el-image
        :src="android_logo"
        style="min-height: 100px"
        v-on:click="$router.push('andform')"
      ></el-image>
      <div>Android</div>
    </el-col>
    <el-col :span="3" :offset="2">
      <el-image
        :src="ios_logo"
        style="min-height: 100px"
        v-on:click="$router.push('iosform')"
      ></el-image>
      <div>iOS</div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "OS",
  props: {
    msg: String,
  },
  setup() {
    const android_logo = require("@/assets/android_logo.png");
    const ios_logo = require("@/assets/ios_logo.png");

    return {
      android_logo,
      ios_logo,
    };
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#row1 {
  margin-top: 10vh;
}

#row2 {
  margin-top: 20vh;
}
</style>
