<template>
  <el-row id="row1">
    <el-col :span="18" :offset="3"
      ><div>请扫码下载App</div>
      <div>完成注册后即可开启您的专属康复方案！</div></el-col
    >
  </el-row>
  <el-row id="row2">
    <el-col :span="8" :offset="3">
      <el-image
        :src="medmotion_android_qrcode"
        style="min-height: 100px"
      ></el-image>
      <div>Android</div>
    </el-col>
    <el-col :span="8" :offset="2">
      <el-image
        :src="medmotion_ios_qrcode"
        style="min-height: 100px"
      ></el-image>
      <div>iOS</div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "AppDownload",
  props: {
    msg: String,
  },
  setup() {
    const medmotion_android_qrcode = require("@/assets/medmotion_android_qrcode.png");
    const medmotion_ios_qrcode = require("@/assets/medmotion_ios_qrcode_v3_appstore.png");

    return {
      medmotion_android_qrcode,
      medmotion_ios_qrcode
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#row1 {
  margin-top: 10vh;
}

#row2 {
  margin-top: 20vh;
}
</style>
